<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M56 530 c-37 -44 -50 -101 -50 -210 0 -109 13 -166 50 -210 51 -61
119 -27 149 73 19 65 19 209 0 274 -30 100 -98 134 -149 73z m83 -114 c23 -91
5 -240 -27 -234 -9 2 -20 19 -25 38 -10 39 -5 208 6 228 16 24 35 11 46 -32z"/>
<path d="M210 558 c0 -2 9 -25 19 -53 17 -46 22 -50 56 -53 34 -3 36 -2 30 20
-17 55 -41 83 -73 86 -18 2 -32 2 -32 0z"/>
<path d="M343 508 c18 -45 21 -73 21 -188 0 -115 -3 -143 -21 -187 -23 -57
-16 -68 16 -27 35 45 46 94 46 214 0 120 -11 169 -46 214 -32 41 -39 30 -16
-26z"/>
<path d="M411 523 c10 -20 19 -45 19 -55 0 -14 8 -18 41 -18 37 0 40 2 34 23
-17 56 -40 82 -77 85 l-36 3 19 -38z"/>
<path d="M534 508 c30 -111 32 -257 6 -346 -7 -23 -10 -45 -7 -49 16 -15 59
20 80 65 44 96 22 296 -36 337 -32 23 -51 19 -43 -7z"/>
<path d="M258 375 c4 -32 7 -35 38 -35 32 0 34 2 34 35 0 34 -1 35 -38 35 -38
0 -38 0 -34 -35z"/>
<path d="M444 388 c3 -13 6 -29 6 -35 0 -8 14 -13 35 -13 33 0 35 2 35 35 0
34 -1 35 -41 35 -38 0 -41 -2 -35 -22z"/>
<path d="M258 265 c-4 -35 -4 -35 34 -35 37 0 38 1 38 35 0 33 -2 35 -34 35
-31 0 -34 -3 -38 -35z"/>
<path d="M446 278 c-11 -43 -7 -48 34 -48 39 0 40 1 40 35 0 33 -2 35 -34 35
-26 0 -35 -5 -40 -22z"/>
<path d="M244 173 c-3 -10 -12 -33 -20 -51 -19 -44 -19 -44 18 -40 32 3 56 32
73 86 6 20 3 22 -30 22 -25 0 -37 -5 -41 -17z"/>
<path d="M430 172 c0 -10 -9 -34 -19 -55 l-19 -37 33 0 c35 0 60 26 75 81 8
28 8 29 -31 29 -31 0 -39 -4 -39 -18z"/>
</g>
</svg>
</template>