<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M105 535 c-25 -24 -25 -26 -25 -215 0 -257 -17 -240 240 -240 257 0
240 -17 240 240 0 257 17 240 -240 240 -189 0 -191 0 -215 -25z m86 -191 c13
-50 20 -48 39 9 15 47 29 44 48 -11 16 -50 28 -47 37 8 4 22 11 40 17 40 6 0
8 -12 4 -32 -13 -66 -27 -108 -36 -108 -5 0 -18 24 -28 53 -17 43 -22 48 -28
32 -22 -61 -34 -85 -42 -85 -5 0 -14 21 -20 48 -6 26 -14 57 -18 70 -4 13 -2
22 5 22 6 0 16 -21 22 -46z m277 34 c5 -7 10 -33 10 -58 0 -58 -11 -70 -64
-70 l-44 0 0 70 0 70 44 0 c24 0 48 -6 54 -12z"/>
<path d="M390 349 c0 -17 5 -20 32 -17 22 2 34 9 36 21 3 14 -4 17 -32 17 -30
0 -36 -4 -36 -21z"/>
<path d="M390 291 c0 -17 6 -21 36 -21 28 0 35 3 32 18 -2 11 -14 18 -36 20
-27 3 -32 0 -32 -17z"/>
</g>
</svg>
</template>