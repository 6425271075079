<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M76 484 c-23 -23 -23 -305 0 -328 23 -24 465 -24 488 0 23 23 23 305
0 328 -23 24 -465 24 -488 0z m464 -164 l0 -140 -220 0 -220 0 0 140 0 140
220 0 220 0 0 -140z"/>
<path d="M145 407 c-3 -7 -5 -52 -3 -98 2 -67 6 -84 18 -84 12 0 15 17 15 94
0 67 -4 96 -12 98 -7 3 -15 -2 -18 -10z"/>
<path d="M227 402 c-34 -37 -13 -94 38 -104 36 -6 23 -33 -17 -36 -30 -3 -38
-7 -38 -23 0 -15 7 -19 34 -19 48 0 66 9 77 36 13 37 -5 73 -41 80 -46 10 -41
42 8 46 53 4 51 32 -4 36 -29 2 -45 -2 -57 -16z"/>
<path d="M379 401 c-21 -16 -24 -28 -24 -81 0 -53 3 -65 24 -81 32 -26 67 -24
96 6 36 35 36 115 0 150 -29 30 -64 32 -96 6z m70 -35 c7 -8 11 -31 9 -52 -6
-73 -68 -68 -68 5 0 54 32 79 59 47z"/>
</g>
</svg>
</template>