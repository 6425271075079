<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M263 633 c-22 -4 -23 -8 -23 -114 0 -60 2 -109 4 -109 3 0 66 36 142
79 l137 80 -34 20 c-66 41 -154 58 -226 44z"/>
<path d="M155 592 c-17 -11 -46 -34 -64 -53 -37 -36 -85 -129 -73 -141 4 -3
46 -29 95 -57 l87 -50 0 159 c0 88 -3 160 -7 160 -5 0 -22 -8 -38 -18z"/>
<path d="M458 489 c-48 -28 -86 -55 -83 -59 5 -8 249 -150 259 -150 13 0 5 86
-13 137 -16 48 -60 123 -71 123 -3 0 -44 -23 -92 -51z"/>
<path d="M2 302 c6 -61 39 -146 76 -191 l19 -24 92 53 c50 29 89 56 86 60 -3
5 -189 115 -269 158 -5 3 -7 -21 -4 -56z"/>
<path d="M450 184 l0 -155 28 15 c45 24 103 87 128 140 13 27 22 51 21 52 -1
1 -41 25 -89 53 l-88 50 0 -155z"/>
<path d="M262 141 c-128 -76 -132 -79 -110 -93 62 -38 141 -54 213 -44 l45 7
0 105 c0 57 -3 104 -7 103 -5 0 -68 -36 -141 -78z"/>
</g>
</svg>
</template>