<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M307 544 c-4 -4 -7 -66 -7 -138 l0 -130 -48 47 c-50 50 -69 57 -79
31 -4 -11 17 -39 68 -90 40 -41 78 -74 85 -74 6 0 43 33 82 74 50 51 70 79 66
90 -10 26 -31 19 -79 -25 l-44 -41 -3 128 c-3 107 -6 129 -18 132 -9 1 -19 0
-23 -4z"/>
<path d="M102 208 c-21 -21 -14 -84 12 -115 l24 -28 187 0 c184 0 187 0 206
24 21 26 27 115 9 126 -20 12 -40 -17 -40 -56 l0 -39 -175 0 c-194 0 -179 -6
-187 69 -3 28 -19 36 -36 19z"/>
</g>
</svg>

</template>
