<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M243 619 c-62 -30 -88 -78 -88 -159 1 -53 7 -76 32 -125 36 -71 119
-185 133 -185 14 0 97 115 130 180 53 105 52 185 -3 247 -57 65 -129 79 -204
42z m97 -99 c54 -29 40 -100 -20 -100 -59 0 -74 69 -22 99 21 13 20 13 42 1z"/>
<path d="M304 479 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16
-11z"/>
<path d="M104 265 c-8 -20 -26 -61 -39 -92 -14 -31 -23 -58 -21 -61 5 -4 153
72 168 86 4 4 -6 28 -22 55 -37 59 -65 63 -86 12z"/>
<path d="M435 231 c-43 -66 -96 -121 -115 -121 -5 0 -24 13 -43 29 -23 21 -37
27 -48 21 -11 -8 -1 -23 49 -72 l62 -63 120 60 c135 67 130 58 85 158 -34 78
-51 76 -110 -12z"/>
<path d="M89 90 c-72 -37 -96 -60 -83 -81 6 -11 294 -12 294 -2 0 12 -113 123
-125 123 -5 -1 -44 -19 -86 -40z"/>
<path d="M485 57 l-100 -52 119 -3 c66 -1 124 2 129 7 10 10 -26 101 -40 101
-4 -1 -53 -24 -108 -53z"/>
</g>
</svg>
</template>