<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M310 595 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M270 586 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M356 591 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M243 532 c-23 -15 -63 -124 -63 -174 l0 -50 35 30 c67 60 155 55 221
-12 66 -66 72 -154 14 -220 -20 -22 -30 -45 -30 -65 l0 -31 93 0 c59 0 97 4
105 12 17 17 17 359 0 376 -7 7 -42 12 -86 12 l-73 0 -26 58 c-14 31 -30 60
-36 64 -7 4 -41 8 -77 8 -36 0 -70 -4 -77 -8z m165 -57 l22 -45 -110 0 -110 0
22 45 22 45 66 0 66 0 22 -45z m192 -140 l0 -45 -60 0 -60 0 0 45 0 45 60 0
60 0 0 -45z"/>
<path d="M252 475 l-12 -25 80 0 80 0 -12 25 c-10 22 -17 25 -68 25 -51 0 -58
-3 -68 -25z"/>
<path d="M500 335 c0 -22 4 -25 40 -25 36 0 40 3 40 25 0 23 -4 25 -40 25 -36
0 -40 -2 -40 -25z"/>
<path d="M40 470 c0 -17 7 -20 50 -20 43 0 50 3 50 20 0 17 -7 20 -50 20 -43
0 -50 -3 -50 -20z"/>
<path d="M70 420 c0 -5 -8 -10 -18 -10 -38 0 -42 -18 -42 -200 0 -124 4 -180
12 -188 8 -8 66 -12 195 -12 l183 0 0 24 c0 22 -2 23 -38 13 -53 -14 -106 -1
-149 36 -56 49 -63 77 -56 211 l6 116 -26 0 c-15 0 -27 5 -27 10 0 6 -9 10
-20 10 -11 0 -20 -4 -20 -10z"/>
<path d="M248 339 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 50 -25 90
-25 140 0 45 23 80 80 80 130 0 50 -35 107 -80 130 -49 25 -94 25 -142 -1z
m156 -48 c26 -27 31 -39 31 -81 0 -43 -5 -54 -33 -82 -28 -28 -39 -33 -82 -33
-43 0 -54 5 -82 33 -28 28 -33 39 -33 81 0 52 25 92 70 112 37 16 96 3 129
-30z"/>
<path d="M271 296 c-87 -48 -50 -186 49 -186 51 0 100 49 100 99 0 75 -83 124
-149 87z m114 -56 c0 -18 -6 -26 -23 -28 -13 -2 -25 3 -28 12 -10 26 4 48 28
44 17 -2 23 -10 23 -28z"/>
<path d="M350 240 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
</g>
</svg>
</template>