<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M276 619 c-52 -41 -23 -122 44 -122 67 0 96 81 44 122 -15 12 -34 21
-44 21 -10 0 -29 -9 -44 -21z"/>
<path d="M260 461 c-59 -12 -60 -14 -60 -116 0 -89 1 -94 23 -97 20 -3 22 -9
25 -83 2 -48 8 -82 15 -87 16 -10 98 -10 114 0 7 5 13 39 15 87 3 74 5 80 26
83 21 3 22 8 22 97 0 80 -3 96 -17 104 -26 13 -122 21 -163 12z"/>
<path d="M140 175 c-95 -30 -116 -96 -42 -134 141 -71 467 -41 488 45 10 41
-29 74 -112 94 -18 4 -38 4 -44 0 -23 -14 -1 -39 44 -51 61 -15 75 -33 39 -47
-105 -42 -281 -42 -386 0 -36 14 -22 32 38 47 50 12 62 25 45 46 -15 18 -12
18 -70 0z"/>
</g>
</svg>
</template>