<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M264 625 c-10 -26 10 -36 62 -33 41 3 49 6 49 23 0 17 -8 20 -53 23
-41 2 -54 0 -58 -13z"/>
<path d="M300 560 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0
-20 -4 -20 -10z"/>
<path d="M70 515 c-18 -19 -31 -38 -27 -41 7 -7 72 44 81 64 11 24 -21 11 -54
-23z"/>
<path d="M547 508 c23 -23 46 -39 49 -35 10 10 -56 77 -75 77 -10 0 0 -16 26
-42z"/>
<path d="M277 519 c-128 -20 -216 -125 -216 -259 -2 -188 208 -317 377 -231
51 26 115 99 130 149 58 193 -95 373 -291 341z m139 -38 c56 -25 110 -84 128
-139 20 -57 20 -85 0 -151 -41 -138 -206 -205 -334 -135 -142 77 -168 262 -54
378 64 64 177 85 260 47z"/>
<path d="M246 465 c-22 -8 -55 -29 -74 -48 -33 -33 -34 -35 -18 -63 20 -38 35
-55 70 -82 40 -30 44 -28 36 18 -8 45 0 98 23 153 18 41 17 42 -37 22z"/>
<path d="M309 444 c-14 -23 -24 -59 -27 -92 l-5 -55 24 22 c36 34 88 58 142
66 l48 7 -23 25 c-27 29 -61 48 -104 57 -29 6 -34 4 -55 -30z"/>
<path d="M440 362 c-31 -3 -100 -33 -100 -42 0 -4 18 -13 39 -20 42 -14 83
-46 118 -95 l21 -29 11 41 c8 29 8 55 1 91 -12 53 -26 62 -90 54z"/>
<path d="M115 330 c-10 -32 -8 -112 5 -144 10 -28 12 -29 66 -22 48 5 114 29
114 40 0 2 -25 16 -55 31 -32 15 -66 42 -82 63 -41 56 -41 56 -48 32z"/>
<path d="M298 283 c-15 -19 1 -48 28 -48 28 0 32 51 5 58 -11 3 -26 -2 -33
-10z"/>
<path d="M382 243 c12 -44 4 -101 -21 -154 -13 -27 -15 -39 -7 -39 25 0 115
52 130 75 15 23 15 28 2 54 -15 28 -92 101 -107 101 -4 0 -3 -17 3 -37z"/>
<path d="M341 206 c-23 -27 -85 -55 -142 -64 l-51 -7 27 -27 c36 -36 112 -65
134 -51 27 17 52 81 49 126 -3 37 -4 39 -17 23z"/>
<path d="M110 485 c-18 -21 -3 -32 16 -13 10 9 12 18 6 22 -5 3 -15 -1 -22 -9z"/>
<path d="M506 491 c-9 -13 23 -36 34 -25 6 6 2 14 -10 22 -10 6 -21 8 -24 3z"/>
</g>
</svg>
</template>