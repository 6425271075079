<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M340 560 c-11 -11 -20 -31 -20 -45 0 -24 31 -65 48 -65 4 0 23 16 42
35 50 52 110 45 110 -12 0 -29 -83 -121 -116 -129 -13 -3 -33 1 -48 12 -33 21
-28 28 9 14 25 -9 32 -6 74 32 55 51 63 62 55 82 -10 26 -35 19 -75 -20 -31
-31 -41 -35 -67 -29 -17 3 -36 13 -43 21 -26 32 -55 16 -143 -80 -47 -52 -86
-99 -86 -106 0 -7 13 -35 30 -62 16 -26 30 -52 30 -57 0 -4 -25 -11 -57 -14
-65 -7 -94 -28 -76 -55 9 -14 24 -17 81 -15 159 6 182 24 133 103 -18 29 -30
54 -27 57 2 3 10 -5 16 -17 9 -16 15 -19 23 -11 6 6 21 11 33 11 19 0 26 -11
46 -72 18 -57 28 -74 44 -76 11 -2 26 4 33 12 16 19 -24 171 -53 198 -19 17
-19 19 -3 35 14 15 21 15 37 5 31 -19 43 -15 108 35 66 52 78 69 62 89 -15 17
17 34 65 34 34 0 36 2 33 28 -3 25 -7 27 -50 30 -27 2 -48 7 -48 13 0 5 -11 9
-25 9 -14 0 -25 5 -25 11 0 6 -9 4 -21 -5 -19 -13 -23 -12 -40 5 -25 25 -64
24 -89 -1z"/>
</g>
</svg>
</template>