<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M48 580 c-54 -42 -61 -125 -14 -169 32 -30 78 -41 178 -41 78 0 87 2
93 20 3 11 11 20 16 20 5 0 27 -9 49 -20 68 -35 188 -24 236 21 47 44 40 127
-14 169 -23 19 -41 20 -272 20 -231 0 -249 -1 -272 -20z m126 -36 c30 -29 14
-59 -34 -66 -19 -2 -26 -10 -28 -30 -2 -16 -8 -28 -13 -28 -10 0 -13 123 -2
133 12 13 62 7 77 -9z m116 -4 c24 -24 26 -67 4 -98 -32 -46 -84 -16 -84 48 0
63 41 89 80 50z m121 10 c20 -11 27 -40 9 -40 -5 0 -10 5 -10 10 0 6 -12 10
-26 10 -32 0 -27 -22 6 -30 43 -10 53 -62 15 -76 -23 -9 -62 4 -69 22 -10 25
1 30 22 10 11 -9 26 -14 36 -10 24 9 14 28 -19 34 -40 7 -54 33 -31 59 21 23
38 26 67 11z m142 -5 c-1 -5 -11 -11 -23 -13 -17 -3 -20 -11 -20 -58 0 -30 -4
-54 -10 -54 -5 0 -10 24 -10 54 0 45 -3 54 -20 59 -11 3 -20 10 -20 17 0 7 18
10 53 8 28 -2 51 -7 50 -13z"/>
<path d="M110 515 c0 -12 7 -15 25 -13 33 4 33 22 0 26 -18 2 -25 -1 -25 -13z"/>
<path d="M237 523 c-12 -11 -8 -60 6 -71 25 -21 50 17 36 56 -8 21 -29 29 -42
15z"/>
<path d="M330 373 c0 -24 40 -235 46 -245 5 -7 16 0 34 22 l27 33 49 -63 c27
-35 52 -68 56 -74 8 -11 58 11 58 26 0 5 -23 39 -50 75 -27 36 -50 69 -50 73
0 4 14 13 30 20 17 7 33 17 36 22 3 4 -47 33 -111 63 -125 59 -125 59 -125 48z"/>
</g>
</svg>
</template>